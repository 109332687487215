import React from 'react';
import { Link } from 'gatsby'

class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isDismissed: null }
        this.dismissAlert = this.dismissAlert.bind(this);
    }
    componentDidMount(){
      this.setState({
        isDismissed: sessionStorage.isDismissed
      })
    }

    dismissAlert() {
        sessionStorage.setItem( 'isDismissed', true);
        this.setState({
            isDismissed: sessionStorage.isDismissed
        });
    }
    render() {
        const { id } = this.props;
        return this.state.isDismissed ? null : (
            <div className="alert-tertiary"
                 role="alertdialog"
                 aria-labelledby={id}>
                 <Link to='/covid19info'>
                  <p className="alert-message">mBody Health has moved! <span className='link-style'>Learn more</span> about what we're doing to reopen safely.</p>
                 </Link>
                <button className="alert-dismiss" onClick={this.dismissAlert}>
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <path d="M10,8l5.63-5.63a1.39,1.39,0,0,0-2-2L8,6,2.37.41a1.39,1.39,0,0,0-2,2L6,8,.41,13.63a1.39,1.39,0,1,0,2,2L8,10l5.63,5.63a1.39,1.39,0,0,0,2-2Z" />
                    </svg>
                </button>
            </div>
        );
    }
}

export default Alert
