import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const SubFooter = ({ data }) => (
  <StaticQuery
    query={graphql`
    query{
      site{
        siteMetadata{
          contact{
            phone
            email
          }
        }
      }
    }
    `}
    render={data => (
      <div className="sub-footer">
        <div className="subfooter-container">  
          <div>
            <a href={`tel:${data.site.siteMetadata.contact.phone}`}><p className="subfooter-text contact-text">{data.site.siteMetadata.contact.phone}</p></a>
            <a href={`mailto:${data.site.siteMetadata.contact.email}`}><p className="subfooter-text">{data.site.siteMetadata.contact.email}</p></a>
          </div>
          <div>
            <p className="subfooter-text">
              This Site Made With <span className="heart-shaped-box"></span> by
              <a className="subfooter-text" href="https://www.eponym.design" target="blank" rel="noopener noreferrer">&nbsp;Eponym Design</a>
            </p>
          </div>
        </div>

      </div>
    )}
  />

)

export default SubFooter;
