import React from 'react';
import { Link } from 'gatsby';
import Menu from './Menu';
import Hamburger from './Hamburger';
import logo from '../img/logo.svg';
import MenuMobile from './MenuMobile';
import Alert from './Alert'



class Header extends React.Component {
  state = { menuActive: false }

  toggleMenu = menuActive => {
    this.setState(prevState => ({
      menuActive: !prevState.menuActive,
    }));
  };

  render() {
    return (
      <div id="nav" className="header sticky">
        <div className="container">
          <div className="logo">
            <Link to="/" aria-label="back to home">
              <img alt="MBody Health Akron" src={logo} />
            </Link>
          </div>
          <MenuMobile active={this.state.menuActive} />
          <Menu />
          <Hamburger toggleMenu={this.toggleMenu} />
        </div>
      </div>
    );
  }
}

export default Header;
