import React from 'react'
import Header from './Header'
import Footer from './Footer'
import SubFooter from './SubFooter'
import '../scss/style.scss'


const Layout = props => (
  <React.Fragment>
  <div>
    <div>
      <Header />
      {props.children}
    </div>
    <Footer />
    <SubFooter />
  </div>
  </React.Fragment>
)

export default Layout;
