import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [{name: 'robots', content: 'index, follow'}, {name: 'viewport', content: 'width = device-width,initial-scale = 1'}],
  keywords: [
    "massage",
    "massage akron",
    "massage akron ohio",
    "massage near me",
    "massage therapy",
    "massage therapy akron",
    "massage therapy akron ohio",
    "massage therapy near me",
    "relaxation massage",
    "relaxation massage akron",
    "relaxation massage akron ohio",
    "relaxation massage near me",
    "deep tissue massage",
    "deep tissue massage akron",
    "deep tissue massage akron ohio",
    "deep tissue massage near me",
    "lymphatic drainage massage",
    "lymphatic drainage massage akron",
    "lymphatic drainage massage akron ohio",
    "lymphatic drainage massage near me",
    "hot stone massage",
    "hot stone massage akron",
    "hot stone massage akron ohio",
    "hot stone massage near me",
    "hot stone massage therapy",
    "chair massage",
    "chair massage akron",
    "chair massage akron ohio",
    "chair massage near me",
    "medical massage",
    "medical massage akron",
    "medical massage akron ohio",
    "medical massage near me",
    "clinical massage",
    "clinical massage akron",
    "clinical massage akron ohio",
    "clinical massage near me",
    "corporation massage",
    "corporation massage akron",
    "corporation massage akron ohio",
    "corporation massage near me",
    "back massage",
    "back massage akron",
    "back massage akron ohio",
    "back massage near me",
    "neck massage",
    "neck massage akron",
    "neck massage akron ohio",
    "neck massage near me",
    "arm massage",
    "arm massage akron",
    "arm massage akron ohio",
    "arm massage near me",
    "leg massage",
    "leg massage akron",
    "leg massage akron ohio",
    "leg massage near me",
    "hand massage",
    "hand massage akron",
    "hand massage akron ohio",
    "hand massage near me",
    "foot massage",
    "foot massage akron",
    "foot massage akron ohio",
    "foot massage near me",
    "mbodyhealth",
    "mbodyhealthltd",
    "mbodyhealthltd akron",
    "mbodyhealthltd akron ohio",
    "mbodyhealth ltd",
    "mbodyhealth ltd akron",
    "mbodyhealth ltd akron ohio",
    "mbodyhealth akron",
    "mbodyhealth ohio",
    "mbodyhealth akron ohio",
    "mbody health",
    "mbody health akron",
    "mbody health akron ohio",
    "mbody health akron oh",
    "mbodyhealthltd.com",
    "www.mbodyhealthltd.com",
    "http://www.mbodyhealthltd.com",
    "https://www.mbodyhealthltd.com",
    "http://mbodyhealthltd.com",
    "https://mbodyhealthltd.com",
  ],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
