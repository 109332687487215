import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

const Footer = props => {
  const { menuLinks } = props.data.site.siteMetadata;
  return(
    <div className="footer-strip">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer">
              <h5 className="footer-title">{props.data.site.siteMetadata.title}</h5>
              <ul className="footer-menu">
                {menuLinks.map(link => (
                  <li key={link.name}>
                    <Link to={link.link}>{link.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
